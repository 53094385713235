import { nextAppEnv } from "@Utils/environment";

export const LOADING = "LOADING";
export const NEG_1_INT = -1;
export const NEG_1_STR = "-1";
export const MAX_REQUEST_RETRIES = 3;
export const REQUEST_RETRY_DELAY = 50; // milliseconds
export const LOCALE = {
  EN_US: "en-US",
  EN_CA: "en-CA",
  FR_CA: "fr-CA",
  ES_MX: "es-MX",
};
export const MOCK_CUSTOM_ERR = {
  HEADING: "Danger Will Robinson",
  MESSAGE: "Something bad happened and a lot of robots died",
};
export const MOCK_EMAIL_ADDRESS = "peterparker@webcrawler.com";
export const MOCK_STRONG_PASSWORD = "Password12!@";
export const FORM_FIELD_KEYS = {
  EMAIL: "email",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  PASSWORD: "password",
  CONFIRM_PASSWORD: "confirmedPassword",
};
export const USER_CONTACT_FORM_TYPE = {
  SHIPPING: "shipping",
  BILLING: "billing",
};
export const CONTACT_US_FORM_TYPE = {
  EMAIL: "email",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  PHONE: "phone",
};
export const MEMBERSHIP_CONSTANTS = {
  MEMBERSHIP: "MEMBERSHIP_YEARLY",
  ANNUAL: "annual",
  MONTHLY: "monthly",
};

export const MY_MEMBERSHIP_CONTENT_IDS = {
  MY_ORDERS: "AccountMyOrders",
  COMMUNICATION_PREFERENCES: "AccountCommunicationSection",
  MEMBERSHIP: "AccountMyMembership",
  ACTIVE_MEMBERSHIP: "AccountActiveMemberMyMembership",
  FAVORITES: "AccountMyFavorites",
  YOTPO_REFERRAL: "AccountYotPoReferral",
  PREVIOUSLY_ORDERED: "AccountPreviouslyOrdered",
};

export const ACCOUNT_MENU = {
  ITEM: {
    SHOP: "SHOP",
    HOW_IT_WORKS: "How It Works",
    RECIPES: "Recipes",
    BECOME_A_MEMBER: "Become a Member",
  },
  NAV_ITEM: {
    MY_ORDERS: "My Orders",
    COMMUNICATION_PREFERENCES: "Communication Preferences",
    FAVORITES: "My Favourites",
    MEMBERSHIP: "My Membership",
    YOTPO_REFERRAL: "Refer a Friend",
    LOGIN: "Login",
    LOGOUT: "Log Out",
    PREVIOUSLY_ORDERED: "Previously Ordered",
  },
};

export const FLYOUT_TIME_CA = 3000;
export const FLYOUT_TIME_US = 3000;
export const SCROLL_TIME_CA = 2000;

/* Defining a constant variable named `MINIMUM_THRESHOLD_VALUE_FOR_DELIVERY_OPTION_CA` with a value of
50 and exporting it to be used in other modules. */
export const MINIMUM_THRESHOLD_VALUE_FOR_DELIVERY_OPTION_CA = Number(
  nextAppEnv.feature_toggle.CA.orderMinimumAmount
);

/* The line `export const ADVANCE_SEARCH_MIN_PRODUCT_COUNT = 3;` is defining a constant variable named
`ADVANCE_SEARCH_MIN_PRODUCT_COUNT` with a value of 3. This constant is then exported to be used in
other modules. */
export const ADVANCE_SEARCH_MIN_PRODUCT_COUNT = 3;

//Defining the DY Page Context Types
export const DY_PAGE_CONTEXT = {
  HOMEPAGE: "HOMEPAGE",
  CATEGORY: "CATEGORY",
  PRODUCT: "PRODUCT",
  CART: "CART",
  OTHER: "OTHER",
};

/* It represents the time in milliseconds (1 minute) that is allowed for a redirect 
from a product detail page (PDP) back to a product list page (PLP) for recipes. 
60000ms = 1minute */
export const TIME_TO_REDIRECT_BACK_FROM_PDP_TO_PLP_RECIPES = 60000;
