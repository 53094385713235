import { ENVIRONMENTS } from "@Constants/environment";

export const amInOneOfTheseEnvironments = (
  acceptableEnvList: string[]
): boolean => {
  return acceptableEnvList.some((env) => {
    return process.env.NEXT_PUBLIC_NODE_ENV === env;
  });
};

export const amInNonProdEnvironment = () => {
  return amInOneOfTheseEnvironments([
    ENVIRONMENTS.LOCAL,
    ENVIRONMENTS.DEV,
    ENVIRONMENTS.QA,
    ENVIRONMENTS.STAGE,
  ]);
};

export const nextAppEnv = {
  application: {
    domainRedirect: process.env.NEXT_PUBLIC_DOMAIN_REDIRECT || "",
    locale: process.env.NEXT_PUBLIC_LOCALE || "en-CA",
    publicUrl: process.env.NEXT_PUBLIC_PUBLIC_URL || "",
    defaultPickupStoreId:
      process.env.NEXT_PUBLIC_DEFAULT_PICKUP_STORE_ID || "2901",
  },
  adyen: {
    apiKey: process.env.NEXT_PUBLIC_ADY_API_KEY || "",
    accountName: process.env.NEXT_PUBLIC_ADY_ACCOUNT_NAME || "",
    environment: process.env.NEXT_PUBLIC_ADY_ENVIRONMENT || "",
    clientKey: process.env.NEXT_PUBLIC_ADY_CLIENT_KEY || "",
  },
  paypal: {
    environment: process.env.NEXT_PUBLIC_PAYPAL_ENVIRONMENT || "test",
  },
  applepay: {
    environment: process.env.NEXT_PUBLIC_APPLEPAY_ENVIRONMENT || "test",
  },
  algolia: {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "",
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || "",
    index: process.env.NEXT_PUBLIC_ALGOLIA_INDEX || "",
    indexProductMostPopular:
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCTS_MOST_POPULAR || "",
    indexProductPriceDesc:
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCTS_PRICE_HL || "",
    indexProductPriceAsc:
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCTS_PRICE_LH || "",
    indexProductTitleAsc:
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCTS_AZ_ASC || "",
    indexProductTitleDesc:
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCTS_ZA_DESC || "",
    indexSuggestions:
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_QUERY_SUGGESTIONS || "",
    indexRecipes: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_RECIPES || "",
  },
  api: {
    baseUri: process.env.NEXT_PUBLIC_API_BASE_URI || "",
    staticUri: process.env.NEXT_PUBLIC_STATIC_DATA_BASE_URI || "",
    apiKey: process.env.NEXT_PUBLIC_OCP_APIM_SUB_KEY || "",
  },
  commerceTools: {
    apiUrl: process.env.NEXT_PUBLIC_CTP_API_URL || "",
    baseUrl: process.env.NEXT_PUBLIC_CTP_API_URL || "",
    authUrl: process.env.NEXT_PUBLIC_CTP_AUTH_URL || "",
    authScopes: process.env.NEXT_PUBLIC_CTP_SCOPES || "",
    authProjectKey: process.env.NEXT_PUBLIC_CTP_PROJECT_KEY || "",
    authClientId: process.env.NEXT_PUBLIC_CTP_CLIENT_ID || "",
    authClientSecret: process.env.NEXT_PUBLIC_CTP_CLIENT_SECRET || "",
  },
  contentful: {
    url: process.env.NEXT_PUBLIC_CONTENTFUL_URL || "",
    spaceId: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || "",
    environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENV || "",
    deliveryToken: process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN || "",
  },
  digiCert: {
    id: process.env.NEXT_PUBLIC_DIGICERT_ID || "",
  },
  freshDesk: {
    token: process.env.NEXT_PUBLIC_FRESH_DESK_TOKEN || "",
  },
  googleMaps: {
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "",
    bypass: process.env.NEXT_PUBLIC_GOOGLE_MAPS_BYPASS || "",
  },
  googleTagManager: {
    scriptId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_TAG_SCRIPT_ID || "",
  },
  googleSiteVerification: {
    contentId:
      process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_CONTENT_ID || "",
  },
  klaviyo: {
    baseUri: process.env.NEXT_PUBLIC_KLAVIYO_BASE_URI || "",
    token: process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_TOKEN || "",
    newsletterListId: process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_LISTID || "",
    zipcodeNotifyListId:
      process.env.NEXT_PUBLIC_KLAVIYO_ZIPCODE_NOTIFY_LISTID || "",
  },
  pinterest: {
    contentId: process.env.NEXT_PUBLIC_PINTEREST_CONTENT_ID || "",
  },
  twilio: {
    baseUrl: process.env.NEXT_PUBLIC_TWILIO_API_URL || "",
    accountSid: process.env.NEXT_PUBLIC_TWILIO_ACCOUNT_SID || "",
    token: process.env.NEXT_PUBLIC_TWILIO_AUTH_TOKEN || "",
  },
  yotpo: {
    baseUrl: process.env.NEXT_PUBLIC_YOTPO_BASE_URL || "",
    appKey: process.env.NEXT_PUBLIC_YOTPO_APP_KEY || "",
    widgetAPIKey: process.env.NEXT_PUBLIC_YOTPO_WIDGET_API_KEY || "",
    instanceId: process.env.NEXT_PUBLIC_YOTPO_INSTANCE_ID || "",
  },
  dynamicYield: {
    sectionId: process.env.NEXT_PUBLIC_DY_SECTION_ID || "",
    apiSubscriptionKey:
      process.env.NEXT_PUBLIC_DY_OCP_APIM_SUBSCRIPTION_KEY || "",
  },
  feature_toggle: {
    CA: {
      membership:
        process.env.NEXT_PUBLIC_MEMBERSHIP_FEATURE_ENABLE_FOR_CA || "false",
      click_and_collect:
        process.env.NEXT_PUBLIC_CLICK_AND_COLLECT_FEATURE_ENABLE_FOR_CA ||
        "true",
      gtm_scripts:
        process.env.NEXT_PUBLIC_GTM_DISABLE_IN_QA_AND_DEV_FOR_CA || "false",
      orderMinimumAmount:
        process.env.NEXT_PUBLIC_ORDER_MINIMUM_AMOUNT_FOR_CA || "35",
      saveAddress: process.env.NEXT_PUBLIC_SAVE_ADDRESS_FOR_CA || "false",
      favorites: process.env.NEXT_PUBLIC_FAVORITES_FOR_CA || "true",
      advanceSearch: process.env.NEXT_PUBLIC_ADVANCE_SEARCH_CA || "false",
      enableYotPoReferral:
        process.env.NEXT_PUBLIC_ENABLE_YOTPO_REFERRAL || "false",
    },
  },
};
