import { ReactNode, useEffect } from "react";
import { COOKIE_KEYS } from "@Constants/storage";
import { getCookie } from "@Utils/cookies";
import { dyCreateDyidServerCookie } from "@Utils/dynamicYield";
import {
  useDispatchCartFromCookie,
  useDispatchStoreIdFromStorage,
  useDispatchZipCodeFromStorage,
} from "@wff/hooks/useActionBasedOnStorage";
import { useCookieFromQueryParam } from "@wff/hooks/useCookieFromQueryParam";
import { useCustomer } from "@wff/hooks/useCustomer";
import { useDomain } from "@wff/hooks/useDomain";
import { useLocale } from "@wff/hooks/useLocale";
import { useRouteRedirect } from "@wff/hooks/useRouteRedirect";

interface IAppGate {
  children: ReactNode;
}

const AppGate = (props: IAppGate) => {
  const { getCustomerDetailsIfLoggedIn } = useCustomer();

  useEffect(() => {
    const dyidCookie = getCookie(COOKIE_KEYS.DYID);
    getCustomerDetailsIfLoggedIn();
    dyCreateDyidServerCookie(dyidCookie);
  }, [getCustomerDetailsIfLoggedIn]);

  useDomain();
  useLocale();
  useRouteRedirect();
  useCookieFromQueryParam({
    queryParamKey: "Welcome",
  });
  useDispatchStoreIdFromStorage();
  useDispatchCartFromCookie();
  useDispatchZipCodeFromStorage();
  //ToDo: DY Cookie should be set from BE as a secure cookie.
  //useCookieFromDy();
  return <>{props.children}</>;
};

export default AppGate;
